#accommodations {
  background-color: $secondary_color;
  color: $complementary_color_dark;
  padding-top: 6rem;

  @include size_M {
    padding-top: 4rem;
  }

  .accommodations {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &_slick {
      display: flex;
      flex-direction: column-reverse;

      ul {
        position: relative;
        bottom: unset;
        display: flex !important;
        justify-content: space-evenly;

        @include size_S {
          flex-direction: column;
          align-items: center;
        }

        gap: 1rem;
        margin-bottom: 2rem;

        li {
          width: fit-content;
          opacity: 0.5;
          transition: opacity 300ms;

          &::after {
            content: '';
            position: absolute;
            bottom: -8px;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: $complementary_color_dark;
            opacity: 1;
            transition: opacity 300ms, transform 300ms;
            transform: scale(0);
            transform-origin: center;
          }

          &:hover::after,
          &:focus::after {
            transform: scale(1);
          }

          &:hover {
            opacity: 1;
          }

          &.slick-active::after {
            transform: scale(1);
          }

          &.slick-active {
            opacity: 1;
          }
        }
      }

      &_container {
        position: relative;
        width: 100%;
        z-index: 1;

        &_image {
          width: 100%;
          max-height: 571px;
          padding-bottom: 71px;
          
          @include size_XS {
            max-height: 500px;
            padding-bottom: 0px;
          }

          object-fit: cover;
        }

        &_box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 2rem;
          position: absolute;
          bottom: 0;

          @include size_XS {
            max-height: unset;
            max-width: calc(300px - 4rem);
            padding: unset;
            position: relative;
            box-shadow: unset;
          }

          left: 50%;
          transform: translateX(-50%);
          background-color: $secondary_color;
          max-height: 142px;
          height: 100%;
          max-width: 300px;
          width: 100%;
          border-radius: 10px;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;

          &_title {
            font-weight: 500;
            letter-spacing: 4px;
            text-transform: uppercase;
            color: $complementary_color_light;

            @include size_XS {
              padding: 1rem 0;
            }
          }

          &_links {
            display: flex;
            justify-content: space-between;
            gap: 20px;

            div,
            a {
              width: fit-content;
              position: relative;
              cursor: pointer;
              opacity: 1;

              &::after {
                content: '';
                position: absolute;
                bottom: -4px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $complementary_color_dark;
                opacity: 1;
                transition: opacity 300ms, transform 300ms;
                transform: scale(0);

                @include size_XS {
                  transform: scale(1);
                }

                transform-origin: center;
              }

              &:hover::after,
              &:focus::after {
                transform: scale(1);
              }
            }
          }
        }
      }
    }
  }

  .slick-list {
    overflow: unset;
    overflow-x: clip;
  }
}
