#services_container {
  background-color: $secondary_color;
  color: $complementary_color_dark;
  padding-top: 2rem;

  .services {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &_container {
      display: grid;
      max-width: 800px;
      grid-template-columns: 1fr 1fr 1fr 1fr;

      @include size_M{
        grid-template-columns: 1fr 1fr;
      }

      @include size_S{
        grid-template-columns: 1fr;
      }

      justify-items: center;
      gap: 2rem;
      margin: 0 auto;

      div {
        display: grid;
        grid-template-columns: 1fr 100px;

        @include size_L{
          grid-template-columns: 30px 100px;
        }
        
        gap: 1rem;
      }
    }
  }
}
