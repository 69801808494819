#rules {
  background-color: $secondary_color;
  color: $complementary_color_dark;
  padding-bottom: 4rem;

  .rules {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;

    &_hero {
      display: flex;
      position: relative;

      img {
        height: calc(var(--app-height) - 8rem);

        @include size_S {
          height: calc(var(--app-height) - 6rem);
        }

        width: 100%;
        object-fit: cover;
        object-position: bottom;
      }
    }

    &_container {
      text-align: center;

      h2 {
        text-align: center;
        margin-bottom: 4rem;
      }

      h3 {
        color: $complementary_color_light;
        text-align: start;
        text-transform: uppercase;

        &:not(:nth-of-type(1)) {
          margin-top: 3rem;
        }
      }

      p {
        text-align: start;
      }

      ul {
        padding-left: 4rem;

        li {
          padding-top: 1rem;
          text-align: start;

          &::marker {
            color: $complementary_color_light;
          }
        }
      }

      &_section {
        margin-top: 6rem;

        &_small_print {
          font-size: 0.75rem !important;
          padding-top: 2rem;
        }
      }
    }
  }
}
