#discover {
  background-color: $secondary_color;
  color: $complementary_color_dark;
  padding-top: 6rem;

  @include size_M {
    padding-top: 4rem;
  }

  padding-bottom: 4rem;

  .discover {
    &_container {
      display: grid;
      grid-template-columns: calc(50% - 3rem) calc(50% - 3rem);

      @include size_L{
        grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
        gap: 4rem;
      }

      @include size_M {
        grid-template-columns: 100%;
      }

      gap: 6rem;

      &_text {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        h2 {
          margin: 0;
          margin-bottom: 2rem;
        }

        ul {
          li::marker {
            color: $complementary_color_light;
          }
        }
      }

      &_img {
        * {
          height: 100%;
        }

        img {
          object-fit: cover;
        }

        .slick-slide {
          div {
            display: flex;
          }
        }
      }

      &_buttons {
        display: flex;

        @include size_M {
          justify-content: center;
        }

        padding-top: 2rem;
        gap: 2rem;

        div {
          display: flex;
          cursor: pointer;
          transition: $transition;
        }
      }
    }
  }
}
