#about {
  background-color: $complementary_color_dark;
  color: $secondary_color;
  padding-top: 4rem;

  .about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-bottom: 4rem;

    @include size_M {
      grid-template-columns: 1fr;
    }

    gap: 2rem;

    &_logo {
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 30%;
      }
    }

    &_img {
      display: flex;
    }

    p {
      font-weight: 300;
    }
  }
}
