.gallery {
  background-color: $primary_color;
  color: $secondary_color;
  padding-top: 2rem;
  padding-bottom: 4rem;

  &_container {
    h2 {
      text-align: center;
    }
  }
}
